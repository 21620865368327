import moment from "moment";
import {Alert, List, ListItem, Typography} from "@mui/material";
import React from "react";
import {SuccessChip} from "./SuccessChip";
import {RunItem} from "../../../api/RunAPI";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";

export function HistoryList(props: {
    test: RunItem
}) {
    const history = useAsync2((input) => {
        return api.runner.getHistorical(input)
    }, {
        runItem: props.test.id,
    }, [props.test.id])

    const list = history.asList;

    return (
        <List dense>
            {list.length === 0 && <div style={{padding: 10}}><Alert severity="info">
                No previous runs
            </Alert></div>}
            {list.map((h, index) => (
                <ListItem style={{
                    background: index %2 === 0 ? "hsla(0,0%,0%,0.05)" : "",
                }}>
                    <a style={{flex: 1}} href={`/runs/${h.companySeqNum}?v=${h.runItem}`}>
                        <Typography variant="body2">
                            #{h.companySeqNum} - {moment(h.timestamp).format("MMM DD, YYYY")}
                        </Typography>
                    </a>


                    <Typography variant="body2">
                        {moment.duration(h.durationMs, "ms").asSeconds()}s
                    </Typography>

                    <div style={{width: 16}} />
                    <div style={{minWidth: 100}}>
                        <SuccessChip status={h.status} />
                    </div>
                </ListItem>
            ))}
        </List>
    )
}