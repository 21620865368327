import {useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";

const darkGreys = {
    "100": grey["800"],
    "200": grey["700"],
    "300": grey["600"],
    "400": grey["500"],
    "500": grey["400"],
    "600": grey["300"],
    "700": grey["200"],
    "800": grey["100"],
    "900": grey["50"],

}

export function useGrey() {
    const theme = useTheme();
    if(theme.palette.mode === "dark") {
        return darkGreys;
    }

    return grey
}

export function useDarkMode() {
    const theme = useTheme();
    return theme.palette.mode === "dark";
}