import {APIBase} from "nate-react-api-helpers";
import {WriterAPI} from "./WriterAPI";
import {FilesAPI} from "./FilesAPI";
import {RunAPI} from "./RunAPI";
import {CompanyAPI} from "./CompanyAPI";

class API extends APIBase {
    writer = new WriterAPI(this)
    runner = new RunAPI(this);
    files = new FilesAPI(this);
    company = new CompanyAPI(this);

    constructor() {
        super({
            jwtRefreshEndpoint: "/api/auth/refresh",
            apiVersion: "v1",
        });
    }

    ping() {
        return this.fetcher.get("/api/ping", {})
    }

    logout() {
        window.location.href = "/api/auth/logout"
    }
}

export const api = new API();