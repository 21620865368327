import {useContext, useEffect, useRef} from "react";
import {WriterContext} from "../WriterProvider";
import {EventEmitter, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {css} from "@emotion/css";
import {deepOrange, green, grey, red} from "@mui/material/colors";
import {AnimatedDots} from "./AnimatedDots";
import CancelIcon from '@mui/icons-material/Cancel';
import {Grid} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

let sessionStart = 0;
let sessionStartStr = localStorage.getItem("compileSessionStart")
if(sessionStartStr) {
    sessionStart = parseInt(sessionStartStr)
}

if(Date.now() - sessionStart > 1000 * 60 * 60 * 18) { // 18hrs
    sessionStart = Date.now();
    sessionStartStr = sessionStart.toString();
    localStorage.setItem("compileSessionStart", sessionStartStr)
}

export const loadCompileProgressEmitter = new EventEmitter();

export function CompileProgress() {
    const wrCtx = useContext(WriterContext)

    const list = useAsync2(async (input) => {
        return api.writer.compileProgress(input)
    }, {since: sessionStart}, [])

    const reloadRef = useRef(list.reload)
    reloadRef.current = list.reload;

    useEffect(() => {
       const int = setInterval(() => {
          reloadRef.current()
       }, 1000 * 60) // 1min

       return () => clearInterval(int)
    }, []);

    useEffect(() => {
        const sub = loadCompileProgressEmitter.subscribe(() => {
            reloadRef.current()
        })

        return () => sub.cancel();
    }, []);

    const doneCount = list.asList.filter(i => !i.isActive).length
    const totalCount = list.asList.length

    let pending = 0;
    let failed = 0;
    let success = 0;
    wrCtx.items.forEach(item => {
        if(item.nodeType === "file") {
            if(item.compileStatus === "failed") {
                failed++;
            } else if(item.compileStatus === "success") {
                success++;
            } else {
                pending++;
            }
        }
    })

    return <div style={{
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
        alignItems: "center",
    }}>
        <div style={{fontSize: "0.9rem"}}>
            Robot Loader{totalCount > 0 && <AnimatedDots />}
            {totalCount > 0 && <div style={{fontSize: "0.8rem"}}>Progress: {doneCount} / {totalCount}</div>}
            <div>
                <Grid container spacing={1} style={{fontSize: "1rem"}}>
                    <Grid item style={{color: deepOrange["300"]}} className={iconNumbCss}>
                        <ReplayCircleFilledIcon fontSize="inherit" color="inherit" />
                        <div className="number">{pending}</div>
                    </Grid>
                    <Grid item style={{color: red["300"]}} className={iconNumbCss}>
                        <CancelIcon fontSize="inherit" color="inherit" />
                        <div className="number">{failed}</div>
                    </Grid>
                    <Grid item style={{color: green["300"]}} className={iconNumbCss}>
                        <CheckCircleIcon fontSize="inherit" color="inherit" />
                        <div className="number">{success}</div>
                    </Grid>
                </Grid>

            </div>
        </div>
        <div style={{flex: 1}} />
        <div className={pieCss} style={{
            // @ts-ignore
            "--p": fixZero(doneCount / totalCount),
        }}>
        </div>
    </div>
}

const iconNumbCss = css({
    display: "flex",
    alignItems: "center",
    gap: 3,
    paddingRight: 4,

    "& .number": {
        fontSize: "0.8rem",
    }
})

function fixZero(n: number) {
    if(n === 0) return 3;
    return n;
}

const pieCss = css({
    position: "relative",
    width: 20,
    aspectRatio: 1,
    display: "inline-grid",
    placeContent: "center",
    margin: 5,
    backgroundColor: grey["300"],
    borderRadius: "50%",

    "&:before": {
       content: '""',
       position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
       inset: 0,
       background: "conic-gradient(" + grey["800"] + " calc(var(--p)*1%),#0000 0)",
    }
})