import {Grid, MenuItem, Select, TextField} from "@mui/material";
import {useEffect, useState} from "react";

export function CronBuilder(props: {
    value: string;
    onChange(value: string): void;
}) {
    const [interval, setInterval] = useState("");
    const [time, setTime] = useState("");
    const [day, setDay] = useState("1")

    useEffect(() => {
        if(interval === "") {
            const parts = props.value.split(" ");
            const [_, time, day, __, weekday] = parts;

            setTime(time || "0")

            if(day === "*" && weekday === "*") {
                setInterval("daily");
            } else if(day !== "*" && weekday === "*") {
                setInterval("monthly");
                setDay(day || "1")
            } else {
                setInterval("weekly");
                setDay(weekday || "1")
            }

            return
        }

        const onChange = (value: string) => {
            if(value !== props.value) {
                console.log("onchang", value)
                props.onChange(value);
            }
        }

        switch(interval) {
            case "daily":
                onChange(`0 ${time} * * *`);
                break;
            case "weekly":
                onChange(`0 ${time} * * ${day}`);
                break;
            case "monthly":
                onChange(`0 ${time} ${day} * *`);
                break;
        }
    }, [props.value, time, day, interval]);

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Select size="small" value={interval} onChange={e => setInterval(e.target.value)}>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                </div>
            </Grid>
            {interval === "weekly" && (<>
                <Grid item>On</Grid>
                    <Grid item>
                        <Select size="small" value={day} onChange={e => setDay(e.target.value)}>
                            <MenuItem value="0">Sunday</MenuItem>
                            <MenuItem value="1">Monday</MenuItem>
                            <MenuItem value="2">Tuesday</MenuItem>
                            <MenuItem value="3">Wednesday</MenuItem>
                            <MenuItem value="4">Thursday</MenuItem>
                            <MenuItem value="5">Friday</MenuItem>
                            <MenuItem value="6">Saturday</MenuItem>
                        </Select>
                </Grid>
            </>)}
            {interval === "monthly" && (<>
                <Grid item>On</Grid>
                <Grid item>
                    <Select size="small" value={day} onChange={e => setDay(e.target.value)}>
                        {Array.from({length: 31}, (_, i) => i + 1).map(i => (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item>
                    day of the month
                </Grid>
            </>)}
            <Grid item>
                At
            </Grid>
            <Grid item>
                <Select size="small" value={time} onChange={e => setTime(e.target.value)}>
                    {Array.from({length: 24}, (_, i) => i).map(i => (
                        <MenuItem key={i} value={i}>{i}:00 {i <= 12 ? "am" : "pm"}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                (UTC)
            </Grid>
        </Grid>
    )
}

const intervalName = {
    daily: "day(s)",
    weekly: "week(s)",
    monthly: "month(s)"
}