import React, {useEffect, useMemo, useRef, useState} from "react";
import {FileNode} from "../../../api/WriterAPI";
import {orderByAscending, useAsync2} from "nate-react-api-helpers";
import {Article, Folder, FolderOpen} from "@mui/icons-material";
import {api} from "../../../api/API";
import {SidebarItem} from "../../runs/sidebar/SidebarItem";

export function Tree(props: {
    onSelect(value: FileNode | undefined): void;
}) {
    const wr = useAsync2(() => api.writer.listNodes(), {}, []);
    const files = wr.asList;

    const [dirSelected, setDirSelected] = useState<number>();
    const [fileSelected, setFileSelected] = useState<number>();

    const [dirs, setDirs] = useState<FileNode[]>([])
    useEffect(() => {
        const folders = files.filter(f => f.nodeType === "folder" && !f.parent)
        orderByAscending(folders, f => f.sortOrder)
        setDirs(folders);
    }, [files]);

    const [dirFiles, setDirFiles] = useState<FileNode[]>([])
    useEffect(() => {
        const list = files.filter(f => f.nodeType === "file" && f.parent === dirSelected)
        orderByAscending(list, f => f.sortOrder)
        setDirFiles(list)
    }, [files, dirSelected]);

    const orphans = useMemo(() => {
        return files.filter(f => {
            if(f.nodeType === "folder") return false;
            return !dirs.find(d => d.id === f.parent)
        })
    }, [files, dirs])

    const propsOnSelect = useRef(props.onSelect)
    propsOnSelect.current = props.onSelect;
    useEffect(() => {
        propsOnSelect.current(fileSelected ? files.find(f => f.id === fileSelected) : undefined)
    }, [fileSelected, files]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <SidebarItem
                key="root"
                icon={<FolderOpen />}
                title="Tests"
                onClick={() => setDirSelected(undefined)}
                selected={!fileSelected && !dirSelected}
                indent={-1}
                count={files.filter(f => f.nodeType === "file").length}
            />
            {dirs.map((d) => {
                if(dirSelected === d.id) {
                    return (
                        <React.Fragment key={d.id.toString()}>
                            <SidebarItem
                                key={d.id.toString()}
                                icon={<FolderOpen />}
                                title={d.name}
                                onClick={() => {
                                    if(dirSelected === d.id) {
                                        setDirSelected(undefined)
                                        setFileSelected(undefined);
                                        return;
                                    }

                                    setDirSelected(d.id)
                                    setFileSelected(undefined);
                                }}
                                selected={!fileSelected}
                                count={dirFiles.length}
                            />

                            {dirFiles.map((f) => {
                                return (
                                    <React.Fragment key={f.id.toString()}>
                                        <SidebarItem
                                            indent={1}
                                            title={f.name}
                                            onClick={() => {
                                                if(f.id === fileSelected) {
                                                    setDirSelected(d.id)
                                                    setFileSelected(undefined);
                                                    return;
                                                }

                                                setDirSelected(d.id)
                                                setFileSelected(f.id);
                                            }}
                                            icon={<Article />}
                                            selected={fileSelected === f.id}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )
                }

                const children = files.filter(f => f.parent === d.id);
                return (
                    <SidebarItem
                        key={d.id.toString()}
                        icon={<Folder />}
                        title={d.name}
                        onClick={() => {
                            setDirSelected(d.id)
                            setFileSelected(undefined)
                        }}
                        selected={false}
                        count={children.length}
                    />
                )
            })}
            {orphans.map(f => {
                return <SidebarItem
                    key={f.id}
                    title={f.name}
                    onClick={() => {
                        setDirSelected(undefined)
                        setFileSelected(f.id);
                    }}
                    icon={<Article />}
                    selected={fileSelected === f.id}
                />
            })}
        </div>
    )
}
