import {useEffect, useRef, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function BaseUrl() {
    const [startUrl, setStartUrl] = useState("")
    const result = useAsync2(() => {
        return api.writer.getProjectConfig()
    }, {}, []);
    const config = result.result;

    useEffect(() => {
        if (config) {
            setStartUrl(config.startUrl)
            if(!config.startUrl) {
                setUrlExpanded(true)
            }
        }
    }, [config]);

    const reloadRef = useRef(result.reload)
    reloadRef.current = result.reload;

    const update = useAsyncAction(async (input) => {
        await api.writer.updateProjectConfig(input)
        setUrlExpanded(false)
        reloadRef.current()
    }, [])
    const [urlExpanded, setUrlExpanded] = useState(false);

    return (
        <form onSubmit={e => {
            e.preventDefault()
            update.callback(Object.assign({}, config, {
                startUrl: startUrl
            }))
        }}>
            {result.LoadingElement}
            <div style={{width: 450}}>
                <Accordion expanded={urlExpanded} onChange={(_, exp) => setUrlExpanded(exp)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6">
                            App Start URL
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">
                            What is the URL of the app we're working on? <br/>
                            This should be a staging or testing environment.
                        </Typography>

                        <div style={{height: 16}}/>

                        <TextField
                            label="URL"
                            variant="outlined" size="small" fullWidth
                            value={startUrl} onChange={e => setStartUrl(e.target.value)}
                            type="url"
                            helperText="e.g. https://staging.myapp.com"
                        />

                        <div style={{height: 0}}/>

                        <Grid container spacing={1}>
                            <Grid item xs />
                            <Grid item>
                                {update.LoadingElement}
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" size="small" type="submit">
                                    {config?.startUrl ? "Update": "Save"}
                                </Button>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>

                {startUrl && <>
                    <div style={{height: 16}}/>
                    <Alert severity="success">
                        <AlertTitle>Setup your Test Plans</AlertTitle>
                        <ol style={{paddingLeft: "1.5em"}}>
                            <li>Use the file explorer on the left to create your tests.</li>
                            <li>Use the Test Runs section to run your test plans</li>
                        </ol>
                    </Alert>
                </>}

                <div style={{height: 16}}/>

                <Typography variant="body2">
                    Special setup requirements? <br/>Get assistance at <a
                    href="mailto:onboarding@testingtaxi.com">onboarding@testingtaxi.com</a>.
                    In the mean time, you can keep going.
                </Typography>
            </div>
        </form>
    )
}