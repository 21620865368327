import {TextField} from "@mui/material";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {WriterContext} from "./WriterProvider";
import {first, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {detailContainerCss} from "../runs/NewRun";
import {css} from "@emotion/css";

export function DirPage() {
    const params = useParams<{dir: string, file: string}>();
    const wrCtx = useContext(WriterContext);

    const dir = first(wrCtx.items, f => f.id.toString() === params.dir);
    const update = useAsyncAction(async (input) => {
        await api.writer.upsertNode(input)
        wrCtx.reload();
    }, []);

    const [name, setName] = useState("")

    useEffect(() => {
        setName(dir?.name || "")
    }, [dir]);

    return (
        <div className={detailContainerCss}>
            <form className={formCss} onSubmit={async (e) => {
                e.preventDefault()
                update.callback(Object.assign({}, dir, {
                    name: name,
                }))
            }}>
                <TextField label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth size="small"/>

                <div>Directory Settings</div>
            </form>
        </div>
    )
}

export const formCss = css({
    width: 450
})