import React, {PropsWithChildren, useMemo} from "react";
import {useAsync2} from "nate-react-api-helpers";
import {FileNode} from "../../api/WriterAPI";
import {api} from "../../api/API";

export const WriterContext = React.createContext({
    items: [] as FileNode[],
    loading: true,
    error: null as string | null,
    reload: () => {

    },
    fullNameForId(id: number): string | null | undefined {
        return this.items.find(f => f.id === id)?.name;
    },
    getUrl(id: number): string {
        return "ctxerror"
    }
});

export function WriterProvider(props: PropsWithChildren<{}>) {
    const nodes = useAsync2(() => api.writer.listNodes(), {}, [])
    const reload = nodes.reload;
    const list = nodes.asList;
    const loading = nodes.loading;
    const error = nodes.error;

    const ctx = useMemo(() => {
        return {
            items: list,
            reload: reload,
            loading: loading,
            error: error,
            fullNameForId(id: number) {
                let item = list.find(f => f.id === id);
                if(!item) return null;

                let out: string[] = [item.name];

                while(item.parent) {
                    item = list.find(f => f.id === item?.parent);
                    if(!item) break
                    out.unshift(item.name);
                }

                return out.join(" / ");
            },
            getUrl(id: number): string {
                let item = list.find(f => f.id === id);
                if(!item) return ""

                let out: string[] = [item.id.toString()];

                while(item.parent) {
                    item = list.find(f => f.id === item?.parent);
                    if(!item) break
                    out.unshift(item.id.toString());
                }

                return "/writer/" + out.join("/")
            }
        }
    }, [list, reload, loading, error])

    return <WriterContext.Provider value={ctx}>
        {props.children}
    </WriterContext.Provider>
}