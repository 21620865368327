import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {css} from "@emotion/css";
import {formCss} from "../writer/DirPage";
import {Button, Grid, Typography} from "@mui/material";
import {Tree} from "./newRun/Tree";
import {useNavigate} from "react-router-dom";

export function NewRun() {
    const wr = useAsync2(() => api.writer.listNodes(), {}, []);
    const nav = useNavigate()
    const startRun = useAsyncAction(async (input) => {
        const run = await api.runner.newRun(input)
        nav("/runs/" + run.companySeqNum)
    }, []);

    return (
        <div className={detailContainerCss}>
            <Typography variant="h6">New Run</Typography>
            <div style={{height: 10}}/>
            <Typography variant="body2">The following tests will be run:</Typography>

            <div className={formCss}>
                <Tree files={wr.asList}/>
                <form className={formCss} onSubmit={e => {
                    e.preventDefault()
                    startRun.callback({
                        files: wr.asList.filter(f => f.nodeType === "file" && f.compileStatus === "success").map(f => f.id)
                    })
                }}>
                    <div style={{height: 10}}/>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            {startRun.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button type={"submit"} variant="contained">Start Run</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
);
}

export const detailContainerCss = css({
    padding: 16,
})