import {blueGrey, grey} from "@mui/material/colors";
import {Tab, Tabs, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {SidebarFooter} from "./SidebarFooter";
import {WriterSidebar} from "./pages/writer/WriterSidebar";
import {RunSidebar} from "./pages/runs/RunSidebar";
import {useDarkMode} from "./misc/UseGrey";

export function Sidebar() {

    const loc = useLocation()
    const isWriter = loc.pathname.startsWith("/writer");
    const isRuns = loc.pathname.startsWith("/runs");
    const nav = useNavigate();
    const isDark = useDarkMode()

    return (
        <div style={{
            height: "100%",
            width: 250,
            flexShrink: 0,
            flexBasis: 250,
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            overflow: "hidden",
        }}>
            <div style={{
                margin: 4,
                border: "2px solid " + grey[isDark ? "800" : "200"],
                background: blueGrey[isDark ? "900" : "50"],
                flex: 1,
                borderRadius: 4,
                display: "flex",
                flexDirection: "column",
                overflowX: "hidden",
            }}>
                <div style={{display: "flex", alignItems: "center", padding: 12, paddingBottom: 4}}>
                    <img alt="taxi logo" src="/logo.webp" style={{height: 40, marginRight: 10}}/>
                    <Typography style={{color: grey[isDark ? "500" : "800"], fontWeight: "500"}}>Testing Taxi</Typography>
                </div>

                <Tabs value={isWriter ? "writer" : isRuns ? "runs" : ""} onChange={(_, value) => {
                    if(value === "writer") {
                        nav("/writer")
                    } else if(value === "runs") {
                        nav("/runs")
                    }
                }}>
                    <Tab label="Test Writer" value={"writer"} />
                    <Tab label="Test Runs" value={"runs"} />
                </Tabs>
                {isWriter ? <WriterSidebar /> : isRuns ? <RunSidebar /> : <div style={{flex: 1}} />}
                <SidebarFooter />
            </div>
        </div>
    )
}