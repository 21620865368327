import {Container} from "../../Container";
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    Grid,
    Dialog,
    DialogContent, DialogContentText, DialogActions, DialogTitle
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useState} from "react";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {User} from "../../api/CompanyAPI";


export function Company() {
    const [value, setValue] = useState("")
    const users = useAsync2(() => {
        return api.company.listUsers()
    },{}, [])
    const [confirmDelete, setConfirmDelete] = useState<User|null>(null)
    const update = useAsyncAction(async (user: User) => {
        await api.company.upsertUser(user)
        setConfirmDelete(null)
        setValue("")
        users.reload();
    }, [])

    return (
        <Container>
            <div style={{padding: 16}}>
                <Typography variant="h5">Company Settings</Typography>

                <Typography variant="h6">
                    Users
                </Typography>

                {confirmDelete && <Dialog open>
                    <DialogTitle>
                        Confirm Delete
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete {confirmDelete.email}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {update.LoadingElement}
                        <Button onClick={() => {
                            setConfirmDelete(null)
                        }}>Cancel</Button>
                        <Button onClick={() => {
                            update.callback(Object.assign({}, confirmDelete, {archived: true}))
                        }}>Delete</Button>
                    </DialogActions>
                </Dialog>}

                <div style={{
                    maxWidth: 300,
                }}>
                    <List dense>
                        {users.LoadingOrErrorElement}
                        {users.asList.map(item => {
                           return (
                               <ListItem key={item.id.toString()} secondaryAction={
                                   <IconButton disabled={users.asList.length <= 1} size="small" edge="end" aria-label="delete" onClick={() => {
                                       setConfirmDelete(item)
                                   }}>
                                       <DeleteIcon />
                                   </IconButton>
                               }>
                                   <ListItemText primary={item.email} />
                               </ListItem>
                           )
                        })}
                    </List>

                    <form onSubmit={e => {
                        e.preventDefault()
                        update.callback({email: value, id: -1, archived: false})
                    }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <TextField placeholder={"john@email.com"} size="small" value={value} onChange={e => {
                                    setValue(e.target.value)
                                }} />
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" size="small" type="submit">Add User</Button>
                            </Grid>
                        </Grid>
                        {(update.loading || update.error) && update.LoadingElement}
                    </form>
                </div>
            </div>
        </Container>
    )
}