import {Grid, ListItemButton, Typography} from "@mui/material";
import moment from "moment";
import {SuccessChip} from "./SuccessChip";
import {RunItem} from "../../../api/RunAPI";
import {useGrey} from "../../../misc/UseGrey";
import {testInfo} from "./TestDetails";

export function TestList(props: {
    results: RunItem[]
    selected?: RunItem;
    onSelect(value: RunItem): void;
}) {
    const grey = useGrey();

    return (
        <>
            {props.results.map(r => {
                const {assertions, durationMs} = testInfo(r)

                return (
                    <ListItemButton
                        selected={props.selected?.id === r.id}
                        key={r.id}
                        style={{paddingTop: 4, paddingBottom: 4, borderBottom: "1px solid " + grey["200"]}}
                        onClick={() => props.onSelect(r)}
                    >
                        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                            <div style={{flex: 1}}>
                                <Typography variant="h5"
                                            style={{alignSelf: "center", fontSize: "1.2rem"}}>{r.name}</Typography>

                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography variant="body2">{assertions} assertions</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">Duration {moment.duration(durationMs, "ms").asSeconds()}s</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{padding: 8}}>
                                <SuccessChip status={r.status} />
                            </div>
                        </div>
                    </ListItemButton>
                )
            })}
        </>
    )
}

export function ucWords(input: string) {
    return input.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
}