import {APIBase} from "nate-react-api-helpers";

export type FileNode = {
    id: number;
    name: string;
    nodeType: "file" | "folder"
    parent?: number;
    archived?: boolean
    sortOrder: number;

    compileStatus?: TaskStatus
    specialInstructions: Instruction[];
}

export type Instruction = {
    type: "step" | "login" | "runFile"
    value: string;
    runFile?: number;
}

export type FileDetail = {
    id: number;
    name: string;
    instructions: Instruction[];
    lastTask?: number;
    currentTask?: number;
    compiledInstructions?: CompiledStep[]
    taskStatus?: TaskStatus
    taskBuildImages?: number[];
    dir: number;
}

export type TaskStatus = "pending" | "failed" | "cancelled" | "success"

type RecentRun = {
    id: number;
    testRunName: string;
    testRunId: number;

    status: "success" | "error" | "pending"
    startedAt: DateString;
    duration: string;
}

export type DateString = string;

type CompiledStep = {
    id: number;
    name: string;
    instruction: string;
}

export class SubAPI {
    parent: APIBase;

    get fetcher() {
        return this.parent.fetcher
    }

    constructor(parent: APIBase) {
        this.parent = parent;
    }
}

export class WriterAPI extends SubAPI {
    listNodes() {
        return this.fetcher.get<FileNode[]>("/api/writer/nodes")
    }

    upsertNode(input: FileNode) {
        return this.fetcher.post<FileNode>("/api/writer/node", input)
    }

    getFileDetail(input: {
        node: number
    }) {
        return this.fetcher.get<FileDetail>("/api/writer/node/file-detail", input)
    }

    upsertFile(input: FileDetail) {
        return this.fetcher.post("/api/writer/node/file-detail", input)
    }

    compileInstructions(input: {
        file: number,
        cancel?: true
    }) {
        return this.fetcher.post("/api/writer/node/compile-file", input)
    }

    compileProgress(input: {
        since: number;
    }) {
        return this.fetcher.get<ProgressItem[]>("/api/writer/node/compile-progress", input)
    }

    getProjectConfig() {
        return this.fetcher.get<ProjectConfig>("/api/writer/project-config")
    }

    updateProjectConfig(input: ProjectConfig) {
        return this.fetcher.post("/api/writer/project-config", input)
    }
}

export type ProjectConfig = {
    startUrl: string
}

type ProgressItem = {
    id: number;
    name: string;
    isActive: boolean;
}