import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {IconButton, Tooltip, useTheme} from "@mui/material";
import React, {useContext} from "react";
import {ThemeChanger} from "./App";
import {ExitToApp} from "@mui/icons-material";
import {api} from "./api/API";
import {SidebarItem} from "./pages/runs/sidebar/SidebarItem";
import {useLocation, useNavigate} from "react-router-dom";
import BusinessIcon from '@mui/icons-material/Business';

export function SidebarFooter() {
    const theme = useTheme();
    const toggle = useContext(ThemeChanger)
    const path = useLocation().pathname;
    const nav = useNavigate()

    return (
        <div style={{paddingTop: 10, display: "flex", flexDirection: "column"}}>

            <SidebarItem
                title="Company"
                onClick={() => {
                    nav("/company")
                }}
                icon={<BusinessIcon />}
                selected={path.startsWith("/company")}
            />
            <div>
                <IconButton sx={{ ml: 1 }} onClick={() => toggle(theme.palette.mode !== 'dark')} color="inherit">
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <Tooltip title="Logout">
                    <IconButton onClick={() => api.logout()}>
                        <ExitToApp />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}