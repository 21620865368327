import {createContext, PropsWithChildren, useEffect, useMemo, useState} from "react";
import {EventEmitter} from "nate-react-api-helpers";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export type PromptInput = {
    title: string,
    text: string,
    actions: {
        text: string,
        action: () => void
    }[]
}

export const PromptContext = createContext({
    show: (input: PromptInput) => {

    },
    alert: (title: string, text: string) => {

    },
    confirm: (text: string) => {
        return Promise.resolve<boolean>(true)
    }
})

const emitter = new EventEmitter<PromptInput>()

export function PromptProvider(props: PropsWithChildren<{}>) {
    const ctx = useMemo(() => {
        return {
            show: (input: PromptInput) => {
                emitter.emit(input)
            },
            alert: (title: string, text: string) => {
                emitter.emit({
                    title,
                    text,
                    actions: [{
                        text: "Ok",
                        action: () => {}
                    }]
                })
            },
            confirm: (text: string) => {
                return new Promise<boolean>(resolve => {
                    emitter.emit({
                        title: "Confirm",
                        text,
                        actions: [{
                            text: "Cancel",
                            action: () => resolve(false)
                        }, {
                            text: "Continue",
                            action: () => resolve(true)
                        }]
                    })
                })
            }
        }
    }, [])

    return <PromptContext.Provider value={ctx}>
        {props.children}
        <Prompter />
    </PromptContext.Provider>

}

function Prompter() {
    const [input, setInput] = useState<PromptInput | null>(null)

    useEffect(() => {
        const sub = emitter.subscribe(setInput)
        return () => sub.cancel()
    }, []);

    if(!input) return null;

    return (
        <Dialog open>
            <DialogTitle>{input.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {input.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {input.actions.map(a => {
                    return <Button onClick={() => {
                        a.action()
                        setInput(null)
                    }}>{a.text}</Button>
                })}
            </DialogActions>
        </Dialog>
    )
}
