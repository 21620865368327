import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {ucWords} from "./TestList";
import {Chip, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {TStatus} from "../../../api/RunAPI";
import {HourglassFull} from "@mui/icons-material";

export function SuccessChip(props: {
    status: TStatus;
}) {
    return (
        <Chip
            className={fixDarkCss}
            icon={props.status === "passed" || props.status === "success" ?
                <CheckCircleIcon/> :
                props.status === "pending" ? <HourglassFull /> :
                <CancelIcon color="inherit" />}
            size="small"
            style={{alignSelf: "center"}}
            color={props.status === "passed" || props.status === "success" ? "success" :
                props.status === "pending" ? "info" : "error"}
            label={ucWords(props.status)}/>
    )
}

const fixDarkCss = css({
    // "&.MuiChip-colorError": {
    //     color: "rgba(0, 0, 0, 0.87)",
    //     backgroundColor: "#e16e65",
    // }
})