import {grey} from "@mui/material/colors";
import React from "react";
import {clsx, writerItemCss} from "../../writer/sidebar/WriterItem";
import {css} from "@emotion/css";
import {useDarkMode} from "../../../misc/UseGrey";

export function SidebarItem(props: {
    selected: boolean;
    indent?: number;
    onClick?(): void;
    color?: string;
    icon: any;
    title: string;
    count?: number;
    countColor?: string;
}) {
    const indent = props.indent ? props.indent * 16 : 0;
    const isDark = useDarkMode()

    return (
        <>
            <button
                className={clsx({
                    [writerItemCss]: true,
                    [writerItemSelectedCss]: props.selected,
                })}
                style={{
                    paddingLeft: 10 + indent,
                    // opacity: isDragging ? 0.5 : 1,
                    // backgroundColor: isOver && props.value?.nodeType === "folder" ? theme.palette.primary.light : undefined,
                }}
                onClick={props.onClick}
            >
                <div style={{color: props.color || (isDark ? grey["300"] : grey["600"]), paddingTop: 2}}>
                    {props.icon}
                </div>
                <div style={{paddingLeft: 8, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                    color: (isDark ? grey["300"] : grey["900"])
                }}>
                    {props.title}
                </div>
                {props.count !== undefined ?
                    <div style={{
                        marginLeft: "auto",
                        color: props.countColor || grey["700"],
                        fontSize: "0.8rem"
                    }}>{props.count}</div>
                    : props.countColor ? <>
                        <div style={{marginLeft: "auto", color: props.countColor, fontSize: "0.8rem"}}>●</div>
                    </> : null}
            </button>
        </>
    )
}

const writerItemSelectedCss = css({
    backgroundColor: "rgba(0, 0, 0, 0.08) !important"
})
