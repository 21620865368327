import {Tree} from "./Tree";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {FileNode} from "../../../api/WriterAPI";
import {useState} from "react";

export function ChooseTest(props: {
    onCancel(): void;
    onSelect(value: FileNode): void;
}) {
    const [selected, setSelected] = useState<FileNode>();

    return (
        <Dialog open>
            <DialogTitle>
                Choose a test file
            </DialogTitle>
            <DialogContent>
                <Tree onSelect={setSelected} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" disabled={!selected} onClick={() => {
                    if(!selected) return;
                    props.onSelect(selected);
                }}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}