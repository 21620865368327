import {Container} from "../../Container";
import {useParams} from "react-router-dom";
import {NewRun} from "./NewRun";
import {RunDetail} from "./RunDetail";
import {RunList} from "./RunList";
import {Triggers} from "./triggers/Triggers";
import {Notifications} from "./notifications/Notifications";

export function RunsPage() {
    const params = useParams<{run: string}>();

    return (
        <Container>
            {
                params.run === "new" ? <NewRun /> :
                    params.run === "triggers" ? <Triggers /> :
                    params.run === "notifications" ? <Notifications /> :
                    !!params.run ? <RunDetail /> :
                        <RunList />
            }
        </Container>
    );
}