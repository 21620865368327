import {useAuthenticated} from "nate-react-api-helpers";
import {Alert, Button, Dialog, TextField, Typography} from "@mui/material";
import {css} from "@emotion/css";
import {useState} from "react";
const logo = require("../logo.webp")

export function RequireAuth() {
    const auth = useAuthenticated();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const queryErr = new URLSearchParams(window.location.search).get("loginError")

    if(auth.authenticated) return null;

    return (
        <Dialog open>
            <div style={{padding: 12, paddingTop: 32}}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <img src={logo} style={{height: 80}} alt={"Taxi"}/>
                </div>
                <Typography variant="h6" style={{textAlign: "center", fontSize: "1.8rem"}}>Testing Taxi</Typography>
                <div style={{height: 12}}/>
                <Typography>
                    You must be logged in to use this feature.
                </Typography>
                {queryErr && <Alert severity="error">
                    {queryErr}
                </Alert>}
                <div style={{height: 24}}/>

                {/*<form onSubmit={e => {*/}
                {/*    e.preventDefault();*/}
                {/*}}>*/}
                {/*    <TextField label="Email" autoComplete="email" type="email" value={email}*/}
                {/*               onChange={e => setEmail(e.target.value)} fullWidth/>*/}
                {/*    <div style={{height: 12}}/>*/}
                {/*    <TextField label="Password" autoComplete="current-password" type="password" value={password}*/}
                {/*               onChange={e => setPassword(e.target.value)} fullWidth/>*/}
                {/*    <div style={{height: 12}}/>*/}

                {/*    <Button fullWidth variant="contained" color="primary" type="submit">Login</Button>*/}
                {/*</form>*/}

                {/*<div style={{height: 4}}/>*/}
                <SigninWith
                    icon={<MicrosoftIcon/>}
                    name="Microsoft Account"
                    url={"/api/oauth/setup?provider=microsoftonline"}
                />
                <div style={{height: 4}}/>
                <SigninWith
                    icon={<GoogleIcon/>}
                    name="Google"
                    url={"/api/oauth/setup?provider=google"}
                />
                <div style={{height: 4}}/>
                <SigninWith
                    icon={<GithubIcon/>}
                    name="Github"
                    url={"/api/oauth/setup?provider=github"}
                />
                <div style={{height: 4}}/>
                <SigninWith
                    icon={<AtlassianIcon/>}
                    name="Atlassian"
                    url={"/api/oauth/setup?provider=bitbucket"}
                />
            </div>
        </Dialog>
    )
}

function SigninWith(props: {
    url: string;
    icon: any
    name: string;
}) {
    return (
        <a href={props.url} style={{textDecoration: "none", color: "inherit"}}>
            <button className={googleBtn}>
                <div className="gsi-material-button-state"></div>
                <div className="gsi-material-button-content-wrapper">
                    <div className="gsi-material-button-icon">
                        {props.icon}
                    </div>
                    <span className="gsi-material-button-contents">Sign in with {props.name}</span>
                </div>
            </button>
        </a>
    )
}

function AtlassianIcon() {
    return (
        <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.3024 9.13208C5.03183 8.83445 4.62597 8.86149 4.43657 9.21323L0.0533155
        18.0068C-0.109027 18.3586 0.134487 18.7644 0.513287 18.7644H6.6282C6.8176
        18.7644 7.007 18.6562 7.08818 18.4668C8.44103 15.7611 7.62932 11.6213 5.3024 9.13208Z"
                  fill="url(#paint0_linear)"></path>
            <path d="M8.52267 1.15012C6.06047 5.04635 6.22281 9.34842 7.84624 12.5953C9.46967 15.8421
        10.7143 18.3044 10.7955 18.4938C10.8766 18.6832 11.066 18.7914 11.2554
        18.7914H17.3704C17.7492 18.7914 18.0197 18.3855 17.8303 18.0338C17.8303 18.0338 9.60495 1.58303
        9.3885 1.17718C9.25321 0.771318 8.76618 0.771325 8.52267 1.15012Z" fill="#2684FF"></path>
            <defs>
                <linearGradient id="paint0_linear" x1="7.72023" y1="10.4927" x2="3.08501" y2="18.5211"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0052CC"></stop>
                    <stop offset="0.9228" stop-color="#2684FF"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

function MicrosoftIcon() {
    return (
        <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
            <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
            <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
            <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
        </svg>
    )
}

function GithubIcon() {
    return (
        <svg aria-hidden="true" viewBox="0 0 24 24" version="1.1"
             className="octicon octicon-mark-github v-align-middle color-fg-default">
            <path
                d="M12.5.75C6.146.75 1 5.896 1 12.25c0 5.089 3.292 9.387 7.863 10.91.575.101.79-.244.79-.546 0-.273-.014-1.178-.014-2.142-2.889.532-3.636-.704-3.866-1.35-.13-.331-.69-1.352-1.18-1.625-.402-.216-.977-.748-.014-.762.906-.014 1.553.834 1.769 1.179 1.035 1.74 2.688 1.25 3.349.948.1-.747.402-1.25.733-1.538-2.559-.287-5.232-1.279-5.232-5.678 0-1.25.445-2.285 1.178-3.09-.115-.288-.517-1.467.115-3.048 0 0 .963-.302 3.163 1.179.92-.259 1.897-.388 2.875-.388.977 0 1.955.13 2.875.388 2.2-1.495 3.162-1.179 3.162-1.179.633 1.581.23 2.76.115 3.048.733.805 1.179 1.825 1.179 3.09 0 4.413-2.688 5.39-5.247 5.678.417.36.776 1.05.776 2.128 0 1.538-.014 2.774-.014 3.162 0 .302.216.662.79.547C20.709 21.637 24 17.324 24 12.25 24 5.896 18.854.75 12.5.75Z"></path>
        </svg>
    )
}

function GoogleIcon() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
             style={{display: "block"}}>
            <path fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
            <path fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
            <path fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
            <path fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
    )
}

const googleBtn = css({
    userSelect: "none",
    backgroundColor: "#f2f2f2",
    backgroundImage: "none",
    border: "none",
    borderRadius: "4px",
    boxSizing: "border-box",
    color: "#1f1f1f",
    cursor: "pointer",
    fontFamily: "'Roboto', arial, sans-serif",
    fontSize: "14px",
    height: "40px",
    letterSpacing: "0.25px",
    outline: "none",
    padding: "0 12px",
    position: "relative",
    textAlign: "center",
    transition: "background-color .218s, border-color .218s, box-shadow .218s",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: "100%",
    minWidth: "min-content",

    "& .gsi-material-button-icon": {
        height: "20px",
        marginRight: "12px",
        minWidth: "20px",
        width: "20px",
    },

    "& .gsi-material-button-content-wrapper": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        height: "100%",
        justifyContent: "space-between",
        position: "relative",
        width: "100%",
    },

    "& .gsi-material-button-contents": {
        flexGrow: 1,
        fontFamily: "'Roboto', arial, sans-serif",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        verticalAlign: "top",
    },

    "& .gsi-material-button-state": {
        backgroundColor: "#1f1f1f",
        border: "1px solid #1f1f1f",
        borderRadius: "4px",
        bottom: "0",
        left: "0",
        opacity: "0",
        position: "absolute",
        right: "0",
        top: "0",
        transition: "opacity .218s",
    },

    "&:active .gsi-material-button-state": {
        backgroundColor: "#001d35",
        opacity: "12%",
    },

    "&:focus .gsi-material-button-state": {
        backgroundColor: "#001d35",
        opacity: "12%",
    },

    "&:hover .gsi-material-button-state": {
        boxShadow: "0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)",
        backgroundColor: "#001d35",
        opacity: "8%",
    },
})