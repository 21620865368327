import {api} from "../../../api/API";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


export function BuildImages(props: {
    value?: number[];
}) {
    if(!props.value) return null;

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            flexWrap: "wrap",
            gap: 10,
        }}>
            {props.value.map((value, idx) => {
               return (
                   <Zoom>
                        <img style={{height: 150}} src={api.files.makeUrl(value)} />
                   </Zoom>
               )
            })}
        </div>
    )
}