import {Alert, Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import moment from "moment/moment";
import {StepList} from "./StepList";
import React from "react";
import {HistoryList} from "./HistoryList";
import {SuccessChip} from "./SuccessChip";
import {RunItem} from "../../../api/RunAPI";
import {useSearchParam} from "../../../misc/useSearchParam";
import {Link} from "react-router-dom";

export function testInfo(input: RunItem) {
    let assertions = 0
    let durationMs = 0;

    input.steps.forEach(s => {
        assertions += s.assertions
        durationMs += s.durationMs
    })

    return {
        assertions,
        durationMs
    }
}

export function TestDetails(props: {
    test: RunItem
}) {
    const t = props.test;
    const [tab, setTab] = useSearchParam("t", "steps")

    const {assertions, durationMs} = testInfo(t)

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: 16
            }}>
                <div style={{flex: 1}}>
                    <Typography variant="h5">{t.name}</Typography>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Link to={t.writerUrl}><Typography variant="body2">Edit in Writer</Typography></Link>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{assertions} assertions</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">Duration {moment.duration(durationMs, "ms").asSeconds()}s</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <SuccessChip status={t.status} />
                </div>
            </div>
            {t.status === "failed" && <div>
                <Alert severity="error">
                    {t.error}
                </Alert>
            </div>}

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, tab) => setTab(tab)} aria-label="basic tabs example">
                    <Tab label="Steps" value="steps" />
                    <Tab label="History" value="history" />
                </Tabs>
            </Box>

            {tab === "steps" ? <StepList test={t} /> : <HistoryList test={t} />}
        </>
    )
}