import {SubAPI} from "./WriterAPI";

export class CompanyAPI extends SubAPI {
    listUsers() {
        return this.fetcher.get<User[]>("/api/users", {})
    }

    upsertUser(user: User) {
        return this.fetcher.post("/api/user", user)
    }
}

export interface User {
    id: number;
    email: string;
    archived: boolean
}