import {MenuItem, Select, TextField, Typography} from "@mui/material";
import { grey } from "@mui/material/colors";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {TriggerInfo} from "./TriggerInfo";
import {useState} from "react";

export function Triggers() {

    const triggerList = useAsync2(() => api.runner.listTriggers(), {}, []);
    const [refreshKey, setRefreshKey] = useState(0);

    return (
        <div>
            <div style={{
                padding: 16
            }}>
                <Typography variant="h5">
                    Triggers
                </Typography>

                <Typography variant="body2">
                    Triggers are used to start runs automatically when certain conditions are met.
                </Typography>
            </div>

            {triggerList.LoadingOrErrorElement}

            <div style={{padding: 8, backgroundColor: grey["300"]}}>
                {triggerList.asList.map(l => (
                    <TriggerInfo key={l.id} value={l} onReload={triggerList.reload} />
                ))}

                <TriggerInfo key={refreshKey} onReload={() => {
                    triggerList.reload()
                    setRefreshKey(Date.now())
                }} />
            </div>
        </div>
    )
}