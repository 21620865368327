import {Container} from "../Container";
import {RequireAuth} from "../misc/RequireAuth";
import {useAuthenticated} from "nate-react-api-helpers";
import {useEffect} from "react";

export function Login() {
    const u = useAuthenticated()
    const lastPing = u.lastPingResult

    useEffect(() => {
        if(lastPing) {
            window.location.href = "/"
        }
    }, [lastPing]);

    return (
        <Container>
            <RequireAuth />
        </Container>
    )
}