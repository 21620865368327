import {SidebarItem} from "./sidebar/SidebarItem";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useNavigate, useParams} from "react-router-dom";
import HistoryIcon from '@mui/icons-material/History';
import {sidebarItemContainerCss} from "../writer/WriterSidebar";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import NotificationsIcon from '@mui/icons-material/Notifications';

export function RunSidebar() {
    const nav = useNavigate()
    const params = useParams<{run: string}>();

    return <div className={sidebarItemContainerCss}>
        <SidebarItem
            icon={<AddCircleIcon />}
            title="Start Run"
            selected={params.run === "new"}
            onClick={() => {
                nav("/runs/new")
            }}
        />
        <SidebarItem
            icon={<HistoryIcon />}
            title="Recent Runs"
            selected={!params.run}
            onClick={() => {
                nav("/runs")
            }}
        />

        {params.run && params.run.match(/^[0-9]+$/) &&
            <SidebarItem
                indent={1}
                icon={<DoneAllIcon />}
                title={"Run #" + params.run}
                selected={true}
                onClick={() => {
                    nav("/runs/" + params.run)
                }}
            />
        }

        <SidebarItem
            icon={<AssistantPhotoIcon />}
            title="Triggers"
            selected={params.run === "triggers"}
            onClick={() => {
                nav("/runs/triggers")
            }}
        />

        <SidebarItem
            icon={<NotificationsIcon />}
            title="Notifications"
            selected={params.run === "notifications"}
            onClick={() => {
                nav("/runs/notifications")
            }}
        />
    </div>
}