import {useEffect, useState} from "react";

const states = ["... ", " ...", ". ..", ".. ."]

export function AnimatedDots() {
    const [dots, setDots] = useState(0)
    useEffect(() => {
        const int = setInterval(() => {
            setDots(dots => (dots + 1) % states.length)
        }, 1000)

        return () => clearInterval(int)
    }, [])

    return <span>{states[dots].slice(0, 3)}</span>
}