import {green, grey} from "@mui/material/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React, {useContext, useEffect, useState} from "react";
import {clsx, writerItemCss} from "./WriterItem";
import {css} from "@emotion/css";
import {api} from "../../../api/API";
import { WriterContext } from "../WriterProvider";
import {PromptContext} from "../../../misc/PromptProvider";
import {FileNode} from "../../../api/WriterAPI";
import {useNavigate} from "react-router-dom";

export function CreateEditItem(props: {
    indent: number;
    nodeType: FileNode["nodeType"];
    edit?: FileNode;
    parent?: number;
    onDone(): void;
}) {
    const {indent} = props;
    const [value, setValue] = useState(props.edit ? props.edit.name : "");
    const ctx = useContext(WriterContext);
    const prompt = useContext(PromptContext);
    const [ref, setRef] = useState<HTMLInputElement|null>(null);

    useEffect(() => {
        if(ref) {
            ref.focus();
        }
    }, [ref]);

    const nav = useNavigate();

    return (
        <form className={clsx({
                [writerItemCss]: true,
            })}
              style={{paddingLeft: 10 + indent, backgroundColor: grey["200"]}}
              onSubmit={async (e) => {
                  e.preventDefault()

                  try {
                      const obj = props.edit || {
                          id: 0,
                          name: value,
                          nodeType: props.nodeType,
                          parent: props.parent,
                          specialInstructions: [],
                          instructions: props.nodeType === "file" ? "" : undefined,
                          sortOrder: 9999,
                      }

                      obj.name = value;
                      const result = await api.writer.upsertNode(obj)

                      if(!props.edit) {
                          if(props.nodeType === "folder") {
                              nav(`/writer/${result.id}`)
                          } else {
                              nav(`/writer/${props.parent}/${result.id}`)
                          }
                      }
                  } catch (e: any) {
                      prompt.alert("Error", e.message)
                  }

                  ctx.reload()
                  props.onDone()
              }}
        >
            <input
                ref={setRef}
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
                style={{
                    padding: 4, width: "100%", backgroundColor: "transparent",
                    border: "none",
                    marginRight: 2,
                }}/>

            <button className={iconButtonCss} type="button" onClick={() => props.onDone()}>
                <HighlightOffIcon/>
            </button>
            <button type="submit" className={iconButtonCss} style={{color: green["800"]}}>
                <CheckCircleOutlineIcon color="inherit"/>
            </button>
        </form>
    )
}

const iconButtonCss = css({
    padding: 0,
    border: "none",
    marginLeft: 1,
    marginRight: 1,
    height: "1.8em",
    cursor: "pointer",
})