import {BaseUrl} from "./rootPage/BaseUrl";
import {Typography} from "@mui/material";
import {detailContainerCss} from "../runs/NewRun";

export function RootPage() {
    return (
        <div className={detailContainerCss}>
            <Typography variant="h6">Welcome to Taxi Writer!</Typography>
            <div style={{height: 10}} />
            <Typography variant="body2">
                Let's create a few tests and load them up in our robot!<br/>
                Load them once, run them over and over.
            </Typography>
            <div style={{height: 16}} />
            <BaseUrl />
        </div>
    )
}