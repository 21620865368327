import React, {useCallback} from 'react';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {blue, grey} from "@mui/material/colors";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {WriterPage} from "./pages/writer/WriterPage";
import {RunsPage} from "./pages/runs/RunsPage";
import {AuthProvider} from "nate-react-api-helpers";
import { api } from './api/API';
import {Login} from "./pages/Login";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {Company} from "./pages/company/Company";

const lightTheme = createTheme({
    palette: {
        primary: blue,
    }
})

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: blue,
        error: {
            main: "#e16e65",
            contrastText: "rgba(0, 0, 0, 0.87)"
        }
    }
})

export const ThemeChanger = React.createContext((dark: boolean) => {})

const hasDark = sessionStorage.getItem("dark-mode") === "yes"
const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/writer" />
    },
    {
        path: "/runs",
        element: <RunsPage />,
        children: [{
            path: ":run",
            element: <RunsPage />,
        }]
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/company",
        element: <Company />
    },
    {
        path: "/writer",
        element: <WriterPage />,
        children: [{
            path: ":dir",
            element: <WriterPage />,
            children: [{
                path: ":file",
                element: <WriterPage />,
            }]
        }]
        // dir
        // file
    },
    {
        path: "*",
        element: <Navigate to="/" />
    }
])

function App() {
    const [theme, setTheme] = React.useState(hasDark ? darkTheme : lightTheme)

    const changeTheme = useCallback((tf: boolean) => {
        setTheme(tf ? darkTheme : lightTheme)
        sessionStorage.setItem("dark-mode", tf ? "yes" : "no")
    }, []);

  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthProvider onPing={() => api.ping()}>
              <ThemeChanger.Provider value={changeTheme}>
                  <ThemeProvider theme={theme}>
                      <div style={{height: "100%", display: "flex", flexDirection: "column", background: theme.palette.mode === "light" ? "white" : grey["900"]}}>
                          <CssBaseline />
                          <RouterProvider router={router} />
                      </div>
                  </ThemeProvider>
              </ThemeChanger.Provider>
          </AuthProvider>
      </LocalizationProvider>
  );
}

export default App;
