import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useState} from "react";
import {Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {NotificationInfo} from "./NotificationInfo";

export function Notifications() {
    const triggerList = useAsync2(() => api.runner.listNotifications(), {}, []);
    const [refreshKey, setRefreshKey] = useState(0);

    return (
        <div>
            <div style={{
                padding: 16
            }}>
                <Typography variant="h5">
                    Notifications
                </Typography>

                <Typography variant="body2">
                    Notifications are sent when a run completes. You can configure them to be sent via email or webhook.
                </Typography>
            </div>

            {triggerList.LoadingOrErrorElement}

            <div style={{padding: 8, backgroundColor: grey["300"]}}>
                {triggerList.asList.map(l => (
                    <NotificationInfo key={l.id} value={l} onReload={triggerList.reload}/>
                ))}

                <NotificationInfo key={refreshKey} onReload={() => {
                    triggerList.reload()
                    setRefreshKey(Date.now())
                }}/>
            </div>
        </div>
    )
}