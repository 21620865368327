import {Alert, Button, Grid, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {WriterContext} from "./WriterProvider";
import {first, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {InstructionList} from "./filePage/InstructionList";
import {Instruction} from "../../api/WriterAPI";
import {BuildInfo} from "./filePage/BuildInfo";
import {detailContainerCss} from "../runs/NewRun";
import {formCss} from "./DirPage";
import {PreviousRuns} from "./filePage/PreviousRuns";
import {PromptContext} from "../../misc/PromptProvider";

export function FilePage() {
    const params = useParams<{dir: string, file: string}>();
    const wrCtx = useContext(WriterContext);

    const nodeId = parseInt(params.file || "0");
    const loader = useAsync2((input) => {
        return api.writer.getFileDetail(input)
    }, {
        node: nodeId,
    }, [params.file]);
    const file = loader.result;

    const [name, setName] = useState("")
    const [instructions, setInstructions] = useState<Instruction[]>([])
    const [instructionsChanged, setInstructionsChanged] = useState(false)

    useEffect(() => {
        setName(file?.name || "")
        setInstructions((file?.instructions || []).slice(0))
        setInstructionsChanged(false);
    }, [file]);

    const reloadRef = useRef(wrCtx.reload)
    reloadRef.current = () => {
        wrCtx.reload()
        loader.reload();
    };

    const loading = file?.taskStatus === "pending";
    const reloadStatusRef = useRef(loader.reload)
    reloadStatusRef.current = loader.reload;

    useEffect(() => {
        if(!loading) return;

        const interval = setInterval(() => {
            reloadStatusRef.current();
        }, 5000)

        return () => clearInterval(interval);
    }, [loading]);

    const [showLoadRobotWarning, setShowLoadRobotWarning] = useState(false)

    useEffect(() => {
        if(!file) return;

        if(file.instructions.length > 0 && !file.taskStatus) {
            setShowLoadRobotWarning(true)
        }
    }, [file]);

    const update = useAsyncAction(async (input) => {
        await api.writer.upsertFile(input)
        reloadRef.current();
        setShowLoadRobotWarning(true)
    }, []);

    const nav = useNavigate();
    const prompt = useContext(PromptContext)

    return (
        <div className={detailContainerCss}>
            <form className={formCss} onSubmit={async (e) => {
                e.preventDefault()
                update.callback(Object.assign({}, file, {
                    name: name,
                    instructions: instructions,
                }))
            }}>
                {file ? loader.error ? loader.LoadingOrErrorElement : null : loader.LoadingOrErrorElement}
                <TextField label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth size="small"/>
                <div style={{height: 12}}/>

                <InstructionList value={instructions} onChange={ins => {
                    setInstructions(ins)
                    setInstructionsChanged(true)
                }}
                                 right={
                                     <div>
                                         <Grid container spacing={1}>
                                             <Grid item>
                                                 {update.LoadingElement}
                                             </Grid>
                                             <Grid item>
                                                 <Button variant="outlined" color="primary" size={"small"}
                                                         type="submit">Save</Button>
                                             </Grid>
                                         </Grid>
                                     </div>
                                 }
                />

                {showLoadRobotWarning && <Alert severity="warning" style={{marginTop: 16}}>
                    Don't forget to load your Test Robot!
                </Alert>}
            </form>

            <div style={{height: 32}}/>

            <BuildInfo value={file} instructionsChanged={instructionsChanged} onUpdate={() => reloadRef.current()}/>

            <div style={{height: 32}}/>
            <PreviousRuns id={file?.id}/>
            <div style={{height: 32}}/>
            <Typography variant={"h6"}>
                Danger Zone
            </Typography>
            <div style={{height: 8}}/>
            <Button color="error" size="small" variant="outlined" onClick={async () => {

                const fileNode = wrCtx.items.find(i => i.id === nodeId)
                const ok = await prompt.confirm("Are you sure you want to archive this item?")
                if (ok && fileNode) {
                    try {
                        await api.writer.upsertNode(Object.assign({}, fileNode, {
                            archived: true,
                        }))

                        wrCtx.reload();
                        nav("/writer/" + params.dir)
                    } catch (e: any) {
                        prompt.alert("Error", e.message)
                    }
                }

            }}>
                Delete Test
            </Button>
        </div>
    )
}