import {Accordion, AccordionDetails, AccordionSummary, Typography, useTheme} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {AttachmentGallery} from "./AttachmentGallery";
import {SuccessChip} from "./SuccessChip";
import {grey} from "@mui/material/colors";
import {RunItem, TestStep} from "../../../api/RunAPI";
import {useState} from "react";
import {css} from "@emotion/css";

export function StepList(props: {
    test: RunItem
}) {
    const steps: TestStep[] = [];

    props.test.steps.forEach(s => {
        steps.push(...s.steps)
    })

    const theme = useTheme();
    const isDark = theme.palette.mode === "dark"
    const [expanded, setExpanded] = useState<TestStep[]>([]);

    return (
        <div style={{padding: 8, backgroundColor: isDark ? "black" : grey["200"], overflow: "auto", flex: 1}}>
            {steps.map(s => (
                <Accordion onChange={(_, expanded) => {
                    if (expanded) {
                        setExpanded(old => [...old, s])
                    } else {
                        setExpanded(old => old.filter(x => x !== s))
                    }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        style={{borderBottom: "1px solid " + (isDark ? grey["700"] :grey["300"])}}
                        className={overflowCss}
                    >
                        <div style={{flex: 1, flexBasis: 0,
                            overflow: "hidden", textOverflow: "ellipsis",
                            display: "flex", flexDirection: "column",
                        }}>
                            {expanded.indexOf(s) !== -1 ? <div style={{}}>{s.name}</div> :
                                <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",}}>
                                    {ellipsis(s.name, 60)}
                                </div>}
                            {s.status === "failed" && <Typography color="error" variant="body2">
                                {s.error}
                            </Typography>}
                        </div>

                        {s.status === "passed" ? <CheckCircleIcon color="success"/> : <SuccessChip status={s.status} />}
                        <div style={{flexShrink: 0, width: 8}}/>
                        <Typography variant="body2" style={{flexShrink: 0, alignSelf: "center", width: 70}}>
                            {s.durationMs}ms
                        </Typography>
                        <div style={{flexShrink: 0, width: 16}}/>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AttachmentGallery attachments={s.attachments} />
                        <div style={{height: 16}} />
                        <Typography variant="body2" style={{fontFamily: "monospace", background: isDark ? "black" : grey["300"], padding: 8, borderRadius: 4}}>
                            <code style={{whiteSpace: "pre-wrap"}}>
                                {s.log}
                            </code>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}

function ellipsis(value: string, maxLen: number) {
    if (value.length + 3 > maxLen) {
        return value.substring(0, maxLen) + "..."
    }

    return value

}

const overflowCss = css({
    "& .MuiAccordionSummary-content": {
        overflow: "hidden",
    }
})