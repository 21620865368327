import {Container} from "../../Container";
import {WriterProvider} from "./WriterProvider";
import {RequireAuth} from "../../misc/RequireAuth";
import {useParams} from "react-router-dom";
import {RootPage} from "./RootPage";
import { DirPage } from "./DirPage";
import {FilePage} from "./FilePage";

export function WriterPage() {

    const params = useParams<{dir: string, file: string}>();

    return (
        <WriterProvider>
            <Container>
                {params.file ? <FilePage key={params.file} /> :
                    params.dir ? <DirPage /> : <RootPage />}
                <RequireAuth />
            </Container>
        </WriterProvider>
    );
}