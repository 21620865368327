import React, {PropsWithChildren} from "react";
import {Sidebar} from "./Sidebar";
import {PromptProvider} from "./misc/PromptProvider";

export function Container(props: PropsWithChildren<{}>) {

    return (
        <PromptProvider>
            <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "stretch",
                overflow: "hidden",
                flexWrap: "nowrap",
            }}>
                <Sidebar />
                <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "auto"}}>
                    {props.children}
                </div>
            </div>
        </PromptProvider>
    )
}