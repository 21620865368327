import {useCallback, useState} from "react";

export function useSearchParam(key: string, defaultValue: string) {
    const search = new URLSearchParams(window.location.search)
    const searchVal = search.get(key)
    const initValue = searchVal === null ? defaultValue : searchVal;

    const [value, setValue] = useState(initValue)

    const setValue2 = useCallback((value: string) => {
        setValue(value);

        const search = new URLSearchParams(window.location.search)
        if(value === defaultValue) {
            search.delete(key)
        } else {
            search.set(key, value)
        }

        const newUrl = window.location.pathname + "?" + search.toString()
        window.history.pushState({path: newUrl}, "", newUrl)
    }, [defaultValue])

    return [value, setValue2] as const
}