import {css} from "@emotion/css";
import {grey, red} from "@mui/material/colors";
import {useContext, useEffect, useRef, useState} from "react";
import {Button, MenuItem, Select} from "@mui/material";
import {Instruction} from "../../../api/WriterAPI";
import {Close} from "@mui/icons-material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useDarkMode} from "../../../misc/UseGrey";
import {ChooseTest} from "./ChooseTest";
import {WriterContext} from "../WriterProvider";
import {useNavigate} from "react-router-dom";
import PageviewIcon from '@mui/icons-material/Pageview';

export function InstructionList(props: {
    value: Instruction[]
    onChange(value: Instruction[]): void
    right?: any
}) {

    const [list, setList] = useState<Instruction[]>(props.value)
    useEffect(() => {
        const arr = props.value || []
        if(arr.length === 0) {
            arr.push({
                type: "login",
                value: ""
            })

            arr.push({
                type: "step",
                value: ""
            })
        }

        ensure1Blank(arr)
        setList(arr)
    }, [props.value])

    const lastMod = useRef<null|number>(null)

    const onChangeRef = useRef(props.onChange)
    onChangeRef.current = props.onChange;

    const listRef = useRef(list)
    listRef.current = list;

    useEffect(() => {
        const watch = setInterval(() => {
            if(!lastMod.current) return;
            if(Date.now() - lastMod.current < 200) return;
            lastMod.current = null;
            onChangeRef.current(listRef.current)
        }, 200)

        return () => clearInterval(watch)
    }, []);

    const isDark = useDarkMode();
    const [showRunSelect, setShowRunSelect] = useState<number|null>(null)
    const wrCtx = useContext(WriterContext);
    const nav = useNavigate();

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "stretch"}}>
            {showRunSelect !== null && <ChooseTest onCancel={() => setShowRunSelect(null)} onSelect={value => {
                if(!value || showRunSelect === null) return;

                const itm = list[showRunSelect]
                itm.runFile = value.id;
                itm.value = value.name;

                ensure1Blank(list)
                setList(list.slice(0))
                lastMod.current = Date.now()
                setShowRunSelect(null)
            }} />}
            {list.map((ins, index) => {
                return (<div key={index.toString()} style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border: "1px solid " + grey["200"],
                }}>
                    <div style={{fontSize: "0.8rem", paddingRight: 8, paddingLeft: 8}}>
                        {index + 1}.
                    </div>
                    <Select size="small" style={{width: 100}} className={selectCss} value={ins.type || "-"} onChange={e => {
                        let value = e.target.value as any
                        if(value === "-") value = ""

                        list[index] = {
                            type: value,
                            value: "",
                        }

                        ensure1Blank(list)
                        setList(list.slice(0))
                        lastMod.current = Date.now()
                    }}>
                        <MenuItem value={"-"}>Type</MenuItem>
                        <MenuItem value={"login"}>Login as</MenuItem>
                        <MenuItem value={"runFile"}>Run Test</MenuItem>
                        <MenuItem value={"step"}>Action</MenuItem>
                    </Select>
                    {ins.type === "runFile" ?
                        <>
                            <button
                                style={{flex: 1, fontSize: "0.8rem", paddingLeft: 8, textAlign: "left", background: "none", border: "none"}}
                                onClick={() => {
                                    setShowRunSelect(index)
                                }}>
                                {ins.runFile && wrCtx.fullNameForId(ins.runFile) || <i>Select a test to run</i>}
                            </button>
                            <div>
                                <button className={iconButtonCss}
                                        onClick={() => {
                                            setShowRunSelect(index)
                                        }}
                                        style={{color: isDark ? grey["300"] : grey["800"]}}>
                                    <PageviewIcon fontSize="inherit" color="inherit"/>
                                </button>
                            </div>
                            <div>
                                <button className={iconButtonCss}
                                        onClick={() => {
                                            if(!ins.runFile) return;
                                            nav(wrCtx.getUrl(ins.runFile))
                                        }}
                                        style={{color: isDark ? grey["300"] : grey["800"]}}>
                                    <OpenInNewIcon fontSize="inherit" color="inherit"/>
                                </button>
                            </div>
                        </> :
                        <input type="text" placeholder={
                            ins.type === "login" ? "user: <user>, password: <password>" :
                                ins.type === "step" ? "What should I do?" : "..."} className={inputCss}
                               value={ins.value}
                               onChange={e => {
                                   list[index].value = e.target.value
                                   ensure1Blank(list)
                                   setList(list.slice(0))
                                   lastMod.current = Date.now()
                               }}
                        />}

                    <div>
                        <button className={iconButtonCss} onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()

                            list.splice(index, 1)
                            setList(list.slice(0))
                            lastMod.current = Date.now()
                        }}>
                            <Close fontSize="inherit" color="inherit"/>
                        </button>
                    </div>
                </div>)
            })}

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 8,
            }}>
                <Button size="small" onClick={() => {
                    setList(list.concat([{
                        type: "step",
                        value: "",
                    }]))
                    lastMod.current = Date.now()
                }}>Add Step</Button>

                {props.right}
            </div>
        </div>
    )
}

const iconButtonCss = css({
    border: "none",
    background: "none",
    cursor: "pointer",
    padding: 8,
    color: red["300"],
    fontSize: "0.8rem",
    height: "1em",

    "&:hover": {
        color: red["600"],
    }
})

function isBlankInstruction(input: Instruction) {
    // @ts-ignore
    if(input.type === "step" || input.type === "") {
        return input.value === ""
    }

    return false
}

function ensure1Blank(arr: Instruction[]) {
    while(arr.length >= 1 && isBlankInstruction(arr[arr.length-1])) {
        arr.splice(arr.length-1, 1)
    }

    arr.push({
        type: "step",
        value: ""
    })
}

const selectCss = css({
    "& [role=combobox]": {
        minHeight: 0,
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: "0.8rem",
        border: "none",
    },
    "& fieldset" : {
        border: "none",
    }
})

const inputCss = css({
    border: "none",
    padding: 8,
    fontSize: "0.8rem",
    flex: 1
})