import {Notification} from "../../../api/RunAPI";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    FormControlLabel,
    Grid,
    MenuItem,
    Select, Switch,
    TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";

export function NotificationInfo(props: {
    value?: Notification;
    onReload(): void;
}) {
    const [expanded, setExpanded] = useState(false);
    const [kind, setKind] = useState(props.value?.actionType || "-")
    const [email, setEmail] = useState(props.value?.email || "")
    const [webhookUrl, setWebhookUrl] = useState(props.value?.webhookUrl || "")
    const [webhookToken, setWebhookToken] = useState("");
    const [requireToken, setRequireToken] = useState(props.value?.webhookAuthToken ? true : false)
    const [webhookMethod, setWebhookMethod] = useState(props.value?.webhookMethod || "POST")

    const update = useAsyncAction(async (input) => {
        await api.runner.upsertNotification(input)
        props.onReload();
        setExpanded(false);
    }, []);

    const test = useAsyncAction(async (input) => {
        await api.runner.testNotification(input)
    }, []);

    let hostName = ""
    try {
        hostName = new URL(props.value?.webhookUrl || "").hostname
    } catch (e) {
        // ignore
    }

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <div>
                    <div>{props.value?.actionType === "webhook" ?
                        "Webhook " + hostName :
                        props.value?.actionType === "email" ? "Email " :
                        "New Notification"
                    }</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>

                <form onSubmit={e => {
                    e.preventDefault();
                    const base = props.value || {}

                    update.callback(Object.assign({}, base, {
                        actionType: kind,
                        email,
                        webhookMethod,
                        webhookUrl,
                        webhookAuthToken: webhookToken
                    }))
                }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Select size="small" value={kind} onChange={e => setKind(e.target.value)}>
                                <MenuItem value="-">Choose a type</MenuItem>
                                <MenuItem value="webhook">Webhook</MenuItem>
                                <MenuItem value="email">Email</MenuItem>
                            </Select>
                        </Grid>
                        {kind === "email" && (<Grid item xs={12}>
                            <TextField fullWidth label="Email Address(s)"
                                       variant="outlined" size="small" helperText="Separate addresses with a comma"
                                       value={email} onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>)}
                        {kind === "webhook" && (<>
                            <Grid item>
                                <Select size="small" value={webhookMethod} onChange={e => setWebhookMethod(e.target.value)}>
                                    <MenuItem value="POST">POST</MenuItem>
                                    <MenuItem value="PUT">PUT</MenuItem>
                                    <MenuItem value="GET">GET</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs>
                                <TextField fullWidth label="Webhook URL" variant="outlined" size="small"
                                           value={webhookUrl} onChange={e => setWebhookUrl(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Switch value={requireToken} onChange={(_, checked) => setRequireToken(checked)} />} label="Include Bearer Token" />
                            </Grid>
                            {requireToken && <Grid item xs>
                                <TextField label="Bearer Token"
                                    variant="outlined" size="small"
                                    value={webhookToken}
                                    onChange={e => setWebhookToken(e.target.value)}
                                    helperText={"We'll include this bearer token in our request"}
                                />
                            </Grid>}
                        </>)}
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {props.value && <Grid item>
                                    <Button variant={"outlined"} color="error" onClick={() => {
                                        update.callback(Object.assign({}, props.value, {
                                            archived: true
                                        }));
                                    }}>Archive</Button>
                                </Grid>}
                                <Grid item xs/>
                                <Grid item>{test.LoadingElement}</Grid>
                                <Grid item>
                                    <Button variant="outlined" color="primary" onClick={() => {
                                        test.callback({
                                            actionType: kind,
                                            email,
                                            webhookMethod,
                                            webhookUrl,
                                            webhookAuthToken: webhookToken
                                        })
                                    }}>Test</Button>
                                </Grid>
                                {update.loading || update.error ? <Grid item>{update.LoadingElement}</Grid> : null}
                                <Grid item>
                                    <Button variant="contained" color="primary" type={"submit"}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    )
}