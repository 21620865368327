import {Trigger} from "../../../api/RunAPI";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    FormControlLabel, Grid,
    MenuItem,
    Select,
    Switch,
    TextField, Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import moment from "moment";
import {CronBuilder} from "./CronBuilder";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {grey} from "@mui/material/colors";
import {css} from "@emotion/css";

export function TriggerInfo(props: {
    value?: Trigger
    onReload(): void;
}) {
    const [name, setName] = useState(props.value?.name || "Trigger " + moment().format("YYYY-MM-DD HH:mm"))
    const [kind, setKind] = useState(props.value?.triggerType || "-")
    const [webhookUrl, setWebhookUrl] = useState(props.value?.webhookUrl || "")
    const [webhookKey, setWebhookKey] = useState(props.value?.webhookKey || "")
    const [webhookToken, setWebhookToken] = useState("");
    const [cronValue, setCronValue] = useState(props.value?.scheduledCron || "")

    const [requireToken, setRequireToken] = useState(props.value?.webhookRequireToken || false)

    useEffect(() => {
        if (kind === "webhook" && !webhookKey) {
            const key = window.crypto.randomUUID()
            setWebhookKey(key);
            setWebhookUrl("https://app.testingtaxi.com/api/run/trigger/webhook?k=" + key);
        }
    }, [kind, webhookKey]);

    const update = useAsyncAction(async (input) => {
        await api.runner.upsertTrigger(input)
        props.onReload();
        setExpanded(false);
    }, []);

    const [expanded, setExpanded] = useState(!props.value);

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <div>
                    <div>{props.value?.name || "New Trigger"}</div>
                    {props.value?.triggerType === "scheduled" && !expanded ? <div style={{fontSize: "0.8rem"}}>
                        Next run at {moment.utc(props.value.scheduledNextRun).format("YYYY-MM-DD HH:mm")} (UTC)
                    </div> : null}
                </div>
            </AccordionSummary>
            <AccordionDetails>

                <form onSubmit={e => {
                    e.preventDefault();
                    const base = props.value || {}
                    const set: Partial<Trigger> = {
                        name,
                        triggerType: kind as any,
                        webhookUrl,
                        webhookRequireToken: requireToken,
                        scheduledCron: cronValue
                    }

                    if(webhookKey) {
                        set.webhookKey = webhookKey;
                    }

                    if(webhookToken) {
                        set.webhookBearerToken = webhookToken
                    }

                    update.callback(Object.assign({}, base, set))
                }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Select size="small" value={kind} onChange={e => setKind(e.target.value)}>
                                <MenuItem value="-">Choose a type</MenuItem>
                                <MenuItem value="webhook">Webhook</MenuItem>
                                <MenuItem value="scheduled">Scheduled</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs>
                            <TextField fullWidth label="Trigger Name" value={name} onChange={e => setName(e.target.value)} variant="outlined" size="small" />
                        </Grid>
                        {kind === "scheduled" && (<Grid item xs={12}>
                            <CronBuilder value={cronValue} onChange={setCronValue} />
                        </Grid>)}
                        {kind === "webhook" && (<>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Webhook URL" variant="outlined" size="small" value={webhookUrl} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Switch value={requireToken} onChange={(_, checked) => setRequireToken(checked)} />} label="Require Bearer Token" />
                            </Grid>
                            {requireToken && <Grid item xs>
                                {webhookToken && <TextField label="Bearer Token"
                                                            variant="outlined" size="small"
                                                            disabled={!requireToken}
                                                            value={webhookToken}
                                                            helperText={"You will only see this one time. Copy it now."}
                                />}
                                <Button style={{marginLeft: 8}} onClick={() => {
                                    setWebhookToken(window.crypto.randomUUID())
                                }}>Generate Bearer Token</Button>
                            </Grid>}
                        </>)}
                        <Grid item xs={12}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                {update.LoadingElement}
                                <Button variant="contained" color="primary" type={"submit"}>Save</Button>
                            </div>
                        </Grid>
                        {kind === "webhook" && (<Grid item xs={12}>
                            <Typography variant="body2">
                                To test this webhook, run the following command in your terminal:
                            </Typography>
                            {requireToken ? <code className={codeCls}>
                                curl -X POST -H "Authorization: Bearer ******" {webhookUrl}
                            </code> : <code className={codeCls}>
                                curl -X POST {webhookUrl}
                            </code>}
                            <Typography variant="body2">
                                You should see a 200 response and a new run in the "Recent Runs" section if the webhook is working.
                            </Typography>
                        </Grid>)}
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    )
}

const codeCls = css({
    display: 'block',
    padding: 8,
    backgroundColor: grey["100"],
    borderRadius: 4,
    marginBottom: 8,
    fontSize: "0.8rem",
})