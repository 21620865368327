import {Grid, IconButton, ImageList, ImageListItem, Typography, useTheme} from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {grey} from "@mui/material/colors";
import {TestAttachment} from "../../../api/RunAPI";

export function AttachmentGallery(props: {
    attachments?: TestAttachment[]
}) {

    const files = props.attachments?.filter(a => a.type === "file") || [];
    const images = props.attachments?.filter(a => a.type === "image") || [];
    const theme = useTheme();
    const isDark = theme.palette.mode === "dark"

    return (
        <div>
            <div style={{paddingBottom: 4}}>
                <Typography variant="subtitle2">
                    Attachments
                </Typography>
            </div>
            <div style={{
                backgroundColor: isDark ? grey["800"] : grey["200"],
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                marginLeft: -16, marginRight: -16
            }}>
                {images.length > 0 && <ImageList variant="masonry" cols={3} gap={8}>
                    {images.map(a => {
                        if(a.type !== "image") return null;
                        return (
                            <ImageListItem key={a.url} style={{position: "relative"}}>
                                <Zoom>
                                    <img src={a.url}
                                         style={{
                                             maxHeight: 300,
                                         }}
                                    />
                                </Zoom>
                                <IconButton onClick={() => window.open(a.url)} style={{position: "absolute", top: 8, left: 8}}>
                                    <DownloadForOfflineIcon />
                                </IconButton>
                            </ImageListItem>
                        );
                    })}
                </ImageList>}

                <Grid container spacing={1}>

                    {(props.attachments || []).length === 0 && <Grid item key="blank">
                        <Typography variant="body2">
                            No Attachments
                        </Typography>
                    </Grid>}

                    {files.length > 0 && <Grid item xs={12} key="files">
                        <Grid container spacing={1}>
                            {files.map(a => {
                                if(a.type !== "file") return null;

                                return (<Grid item key={a.url}>
                                    <IconButton onClick={() => window.open(a.url)}>
                                        <DownloadForOfflineIcon/>
                                    </IconButton>
                                    <a href={a.url} target="_blank">{a.name}</a>
                                </Grid>)
                            })}
                        </Grid>
                    </Grid>}
                </Grid>
            </div>
        </div>
    )
}