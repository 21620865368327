import {Article, CreateNewFolder, Folder, FolderOpen, NoteAdd} from "@mui/icons-material";
import {deepOrange, green, red} from "@mui/material/colors";
import {useContext, useEffect, useMemo, useState} from "react";
import {orderByAscending} from "nate-react-api-helpers";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import { WriterItem } from "./sidebar/WriterItem";
import {WriterContext} from "./WriterProvider";
import {Alert, CircularProgress} from "@mui/material";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {FileNode} from "../../api/WriterAPI";
import {api} from "../../api/API";
import {PromptContext} from "../../misc/PromptProvider";
import {CompileProgress} from "./sidebar/CompileProgress";
import {css} from "@emotion/css";

export function WriterSidebar() {
    const params = useParams()
    const dirSelected = params["dir"];
    const fileSelected = params["file"];

    const wrCtx = useContext(WriterContext)
    const files = wrCtx.items;

    const [dirs, setDirs] = useState<FileNode[]>([])
    useEffect(() => {
        const folders = files.filter(f => f.nodeType === "folder" && !f.parent)
        orderByAscending(folders, f => f.sortOrder)
        setDirs(folders);
    }, [files]);

    const [dirFiles, setDirFiles] = useState<FileNode[]>([])
    useEffect(() => {
        const list = files.filter(f => f.nodeType === "file" && f.parent?.toString() === dirSelected)
        orderByAscending(list, f => f.sortOrder)
        setDirFiles(list)
    }, [files, dirSelected]);

    const orphans = useMemo(() => {
        return files.filter(f => {
            if(f.nodeType === "folder") return false;
            return !dirs.find(d => d.id === f.parent)
        })
    }, [files, dirs])

    const promptCtx = useContext(PromptContext)
    const nav = useNavigate();

    const spliceDirs = (dragIndex, hoverIndex) => {
        const drag = dirs[dragIndex]
        const hover = dirs[hoverIndex]

        const dragOrder = drag.sortOrder;
        drag.sortOrder = hover.sortOrder
        hover.sortOrder = dragOrder

        // swap places
        const dirs2 = dirs.slice(0)
        orderByAscending(dirs2, f => f.sortOrder)

        setDirs(dirs2)
    }

    const updateSortOrder = async (d: FileNode) => {
        try {
            await api.writer.upsertNode(d)
        } catch (e: any) {
            promptCtx.alert("Error", e.message)
        }

        wrCtx.reload();
    }

    const spliceFiles = (dragIndex, hoverIndex) => {
        try {
            const drag = dirFiles[dragIndex]
            const hover = dirFiles[hoverIndex]

            const dragOrder = drag.sortOrder;
            drag.sortOrder = hover.sortOrder
            hover.sortOrder = dragOrder

            // swap places
            const dirFiles2 = dirFiles.slice(0)
            orderByAscending(dirFiles2, f => f.sortOrder)

            setDirFiles(dirFiles2)
        } catch (e) {
            debugger;
        }
    }

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <div className={sidebarItemContainerCss}>
                    {wrCtx.loading ? <CircularProgress /> : null}
                    {wrCtx.error ? <Alert severity="error">{wrCtx.error}</Alert> : null}
                    <WriterItem
                        key="root"
                        icon={<FolderOpen />}
                        title="Specs"
                        onClick={() => nav("/writer")}
                        nodeType={"folder"}
                        selected={!fileSelected && !dirSelected}
                        indent={-1}
                        count={files.filter(f => f.nodeType === "file").length}
                    />
                    {dirs.map((d) => {
                        if(dirSelected === d.id.toString()) {
                            return (
                                <React.Fragment key={d.id.toString()}>
                                    <WriterItem
                                        key={d.id.toString()}
                                        icon={<FolderOpen />}
                                        title={d.name}
                                        onClick={() => nav("/writer/" + d.id)}
                                        nodeType={"folder"}
                                        value={d}
                                        selected={!fileSelected}
                                        count={dirFiles.length}
                                        countColor={statusColor(dirFiles)}
                                        splice={spliceDirs}
                                        onDrop={() => updateSortOrder(d)}
                                    />

                                    {dirFiles.map((f) => {
                                        return <WriterItem
                                            indent={1}
                                            key={f.id}
                                            title={f.name}
                                            onClick={() => nav("/writer/" + d.id + "/" + f.id)}
                                            icon={<Article />}
                                            selected={fileSelected === f.id.toString()}
                                            nodeType={"file"}
                                            countColor={statusColor([f])}
                                            parent={d.id}
                                            value={f}
                                            splice={spliceFiles}
                                            onDrop={() => updateSortOrder(f)}
                                        />
                                    })}
                                    <WriterItem
                                        indent={1}
                                        icon={<NoteAdd />}
                                        color={green["400"]}
                                        title={"New Test"}
                                        nodeType={"file"}
                                        create
                                        parent={d.id}
                                    />
                                </React.Fragment>
                            )
                        }

                        const children = files.filter(f => f.parent === d.id);
                        return (
                            <WriterItem
                                key={d.id.toString()}
                                icon={<Folder />}
                                title={d.name}
                                onClick={() => nav("/writer/" + d.id)}
                                nodeType={"folder"}
                                value={d}
                                count={children.length}
                                countColor={statusColor(children)}
                                splice={spliceDirs}
                                onDrop={async (droppedItem) => {
                                    if(droppedItem.nodeType === "file") {
                                        // move file to dir
                                        droppedItem.parent = d.id
                                        droppedItem.sortOrder = 9999

                                        try {
                                            await api.writer.upsertNode(droppedItem)
                                        } catch (e: any) {
                                            promptCtx.alert("Error", e.message)
                                        }

                                        wrCtx.reload();
                                        return
                                    }

                                    updateSortOrder(d)
                                }}
                            />
                        )
                    })}
                    {orphans.map(f => {
                        return <WriterItem
                            key={f.id}
                            title={f.name}
                            onClick={() => nav("/writer/0/" + f.id)}
                            icon={<Article />}
                            selected={fileSelected === f.id.toString()}
                            nodeType={"file"}
                            parent={0}
                            value={f}
                        />
                    })}
                    <WriterItem
                        icon={<CreateNewFolder />}
                        color={green["400"]}
                        title={"New Folder"}
                        nodeType={"folder"}
                        create
                    />
                </div>
            </DndProvider>
            <CompileProgress />
        </>
    )
}

export const sidebarItemContainerCss = css({
    display: "flex",
    flexDirection: "column",
    paddingTop: 12,
    overflow: "auto",
    flex: 1,
})

function statusColor(list: FileNode[]) {
    if(list.some(f => f.compileStatus === "failed")) return red["400"]
    if(list.some(f => f.compileStatus === "pending")) return deepOrange["400"]
    if(list.some(f => f.compileStatus === "success")) return green["400"]
    return undefined
}